@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  font-family: "Lato", sans-serif;
  background-color: #f5f5f5 !important;
  overflow-x: hidden;
}

input:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

select:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

textarea:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-blue {
  color: #65b7ff !important;
}

.bg-blue {
  background-color: rgb(100, 153, 233) !important;
}

.bg-blue2 {
  background-color: rgba(100, 153, 233, 0.5) !important;
}

.p-input {
  padding: 0.75rem;
}

.date-picker {
  width: 28%;
}

.text-xs {
  font-size: 0.7rem;
}
